import { postCredentials, registerNewUser, refreshToken, revokeToken } from "API";
import { history } from "helpers";
import toastr from "toastr";
import { closeModal } from "actions";
import { POST } from "API";

export const API_HOST = process.env.REACT_APP_API_HOST;

// AUTH API
export function signIn(credentials) { // bikin async tunggu selesai naro di localstorage
  return dispatch => {
    dispatch(signingIn());
    postCredentials(credentials)
      .then(res => {
        const token = res.data.access_token,
          refresh_token = res.data.refresh_token,
          profile = {username : res.data.profile.username, email: res.data.profile.email, name: res.data.profile.name, role: res.data.privileges.role};

        localStorage.setItem("idn", token);
        localStorage.setItem("idr", refresh_token);
        localStorage.setItem("idp", JSON.stringify(profile));
        dispatch(setAuth({ token, profile }));
        // history.push("/dashboard");
        history.push("/dashboard/overview");
      })
      .catch(err => {
        toastr.error(err.response.data.error_description);
        dispatch(unsetAuth());
      });
  };
}

export function signOut() {
  return async dispatch => {
    revokeToken()
    .then(() => {
      localStorage.removeItem("idn");
      localStorage.removeItem("idp");
      dispatch(unsetAuth());
      window.location.reload();
    })
  };
}

export function reAuth(){
  const reftok = localStorage.getItem('idr');
  return async dispatch => {
    dispatch(signingIn());
    try{
      const res = await POST(`${API_HOST}/sental/oauth/token?grant_type=refresh_token&refresh_token=${reftok}`, null, false);
      localStorage.setItem("idn", res.data.access_token);
      localStorage.setItem("idr", res.data.refresh_token);
      window.location.reload();
    }catch(err){
      dispatch(signOut());
    }
  }
}

export function signUp(values) {
  return dispatch => {
    registerNewUser(values).then(res => {
      toastr.success(res.data.msg);
      dispatch(closeModal());
    });
  };
}

export function signingIn(){
  return {
    type: "START_AUTH"
  }
}

export function setAuth(payload) {
  return {
    type: "SET_AUTH",
    payload
  };
}

export function unsetAuth() {
  return {
    type: "UNSET_AUTH"
  };
}
