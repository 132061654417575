import React, { Component } from "react";
import { reAuth } from "actions/authActions";
// import Iframe from "@nicholasadamou/react-iframe";
import { connect } from "react-redux";
import GoogleTopicTrends from "components/topicmapping//GoogleTopicTrends";
import SidePanelDemography from "../mapping/SidePanelDemography";
import { Box, Button, TextField } from '@mui/material';
import axios from "axios";

import loading from "media/img/components/loading_blue_trans3.gif";
import Modal from 'react-modal';


import "styles/components/map-submenu.css";



class Perception360 extends Component {
  state = {
    isLoading: true,
    iframeRandomKey: 0,
    frameElement: null,
    frameDome: null,
    provinceFilter: 'national',
    urlIframe: 'https://fyp.skyarc.io/perception360_rr',
    isIframeLoaded : false,
    inputPlaceName : null,
    inputAreaName : null,
    region : '31',
    urlTrend: "https://trends24.in/indonesia/",
  }

  handleFrameElement = val => {
    this.setState({
      frameElement: val,
      urlIframe: val.target.src,
      isIframeLoaded: true,
      isLoading: false
      // iframeRandomKey: this.state.iframeRandomKey + 1
    })

    // console.log(val.target);

    // console.log('yyyy');
    // let domSrc = val.target.contentWindow.location;
    // console.log('xxx : ' + domSrc);

    let domTarget = val.target.src;
    //   // .getElementById('clickbutton');
    console.log(domTarget);
  }


  render() {

    const { isLoading } = this.state;

    let domIframe = document.getElementById('iframe');
    // .contentDocument.getElementById('identity')

    if (domIframe != null) {
      console.log(domIframe);
    }

    const modalStyles = {
      content: {
        right: 'auto',
        bottom: 'auto',
        width: '20%',
        height: '30%',
        marginTop: '19%',
        marginBottom: '12%',
        zIndex: '99999 !important'
      },
    };

    return (
      <React.Fragment>
        <iframe style={{height: '100%'}}
          key={this.state.iframeRandomKey}
          id="iframe"
          sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation allow-top-navigation-by-user-activation"
          src={this.state.urlIframe}

          headers={{
            "X-Frame-Options": "allow-from *",
            "Access-Control-Allow-Origin": "*"
          }}

          onLoad={this.handleFrameElement}
        />

          {isLoading && (
            <Modal className="text-center"
            // style={{zIndex: '999999999999'}} 
              isOpen={isLoading}
              // onAfterOpen={afterOpenModal}
              // onRequestClose={closeModal}
              style={modalStyles}
            >
              <span style={{position:'relative', top:'30%', verticalAlign: 'middle'}}>
                <img style={{height: '100px'}} src={loading} alt="loading" />
                <h4>Fetch Social Media Data</h4>
                <h3>Please wait a minute...</h3>
              </span>
              
            </Modal>
          )}

      </React.Fragment>
    );
  }
}

export default Perception360;
