import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setModalRegister } from "actions";
import { signOut } from "actions/authActions";
import logo from "media/img/logo-white.png";
import ModalRegister from "../../shared/ModalRegister";

const localProfile = JSON.parse(localStorage.getItem("idp"));

class Navbar extends Component {
  state = {
    profile: false
  };
  componentDidMount() {
    this.props.profile
      ? this.setState({ profile: this.props.profile })
      : this.setState({ profile: localProfile });
  }
  render() {
    const { pathname } = this.props.location;
    const { profile } = this.state;
    return (
      <Fragment>
        <nav className="top-toolbar navbar navbar-desktop flex-nowrap">
          <ul className="navbar-nav nav-left">
            <li className="nav-item">
              <a href="#" className="top-logo">
                <img src={logo} alt="logo" />
              </a>
            </li>
            {profile &&
              (profile.role === "ROLE_SUPER" ||
                profile.role === "ROLE_ADMIN") && (
                <Fragment>
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/360view"
                      className={`top-menu ${
                        pathname === "/dashboard/360view" ? "active" : ""
                      } `}
                    >
                      360° View
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/overview"
                      className={`top-menu ${
                        pathname === "/dashboard/overview" ? "active" : ""
                      } `}
                    >
                      Overview: Crosstabs
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/360viewVP"
                      className={`top-menu ${
                        pathname === "/dashboard/360viewVP" ? "active" : ""
                      } `}
                    >
                      360° Targeting as VP
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/360persuasion"
                      className={`top-menu ${
                        pathname === "/dashboard/360persuasion" ? "active" : ""
                      } `}
                    >
                      VP Persuasion Model
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link
                      to="/dashboard/overview"
                      className={`top-menu ${
                        pathname === "/dashboard/overview" ? "active" : ""
                      } `}
                    >
                      Overview
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/whatson360"
                      className={`top-menu ${
                        pathname === "/dashboard/whatson360" ? "active" : ""
                      } `}
                    >
                      Whatson 360°
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/osint_sna"
                      className={`top-menu ${
                        pathname === "/dashboard/osint_sna" ? "active" : ""
                      } `}
                    >
                      SNA - OSINT
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/whatson"
                      className={`top-menu ${
                        pathname === "/dashboard/whatson" ? "active" : ""
                      } `}
                    >
                      What's On
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/horserace"
                      className={`top-menu ${
                        pathname === "/dashboard/horserace" ? "active" : ""
                      } `}
                    >
                      Political Figures Simulation
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/choice"
                      className={`top-menu ${
                        pathname === "/dashboard/choice" ? "active" : ""
                      } `}
                    >
                      CAPRES SIMULATION
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/issues"
                      className={`top-menu ${
                        pathname === "/dashboard/issues" ? "active" : ""
                      } `}
                    >
                      Issues
                    </Link>
                  </li> */}
                  {profile && profile.role === "ROLE_SUPER" && (
                    <Fragment>
                      {/* <li className="nav-item">
                    <Link
                      to="/dashboard/scoreanalysis"
                      className={`top-menu ${
                        pathname === "/dashboard/scoreanalysis" ? "active" : ""
                      } `}
                    >
                      Job Approval
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/soe"
                      className={`top-menu ${
                        pathname === "/dashboard/soe" ? "active" : ""
                      } `}
                    >
                      S.O.E
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/dashboard_et"
                      className={`top-menu ${
                        pathname === "/dashboard/dashboard_et" ? "active" : ""
                      } `}
                    >
                      Topline Dashboard
                    </Link>
                  </li> */}
                    </Fragment>)
                  }
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/turnoutscore"
                      className={`top-menu ${
                        pathname === "/dashboard/turnoutscore" ? "active" : ""
                      } `}
                    >
                      Vaccine Insights
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/vaksin"
                      className={`top-menu ${
                        pathname === "/dashboard/vaksin" ? "active" : ""
                      } `}
                    >
                      Vaccine Distribution
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/poi"
                      className={`top-menu ${
                        pathname === "/dashboard/poi" ? "active" : ""
                      } `}
                    >
                      Support Group - P.O.I
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/media_monitor"
                      className={`top-menu ${
                        pathname === "/dashboard/media_monitor" ? "active" : ""
                      } `}
                    >
                      Top Share Content
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link
                      to="/dashboard/fyp_sentiment"
                      className={`top-menu ${
                        pathname === "/dashboard/fyp_sentiment" ? "active" : ""
                      } `}
                    >
                      Sentiment 360 - FYP
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/fyp_gen_ai"
                      className={`top-menu ${
                        pathname === "/dashboard/fyp_gen_ai" ? "active" : ""
                      } `}
                    >
                      Perception AI 360 - FYP
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/360crowd"
                      className={`top-menu ${
                        pathname === "/dashboard/360crowd" ? "active" : ""
                      } `}
                    >
                      360° Crowd Radar
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/perception360"
                      className={`top-menu ${
                        pathname === "/dashboard/perception360" ? "active" : ""
                      } `}
                    >
                      Perception Paslon  360°
                    </Link>
                  </li> */}

                  <li className="nav-item">
                    <Link
                      to="/dashboard/push_rank_fyp"
                      className={`top-menu ${
                        pathname === "/dashboard/push_rank_fyp" ? "active" : ""
                      } `}
                    >
                      Push Rank - FYP
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/takedown_fyp"
                      className={`top-menu ${
                        pathname === "/dashboard/takedown_fyp" ? "active" : ""
                      } `}
                    >
                      Take Down - FYP
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/dashboard/whatson_new"
                      className={`top-menu ${
                        pathname === "/dashboard/whatson_new" ? "active" : ""
                      } `}
                    >
                      What's On
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/daily_dose"
                      className={`top-menu ${
                        pathname === "/dashboard/daily_dose" ? "active" : ""
                      } `}
                    >
                      Daily Dose
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/sna"
                      className={`top-menu ${
                        pathname === "/dashboard/sna" ? "active" : ""
                      } `}
                    >
                      S.N.A
                    </Link>
                  </li> */}
                  
                  {/* {profile && profile.role === "ROLE_SUPER" && (
                    <Fragment> */}
                  {/* </Fragment>
                  )} */}
                </Fragment>
              )}
            {/* {profile &&
              (profile.role === "ROLE_SUPER" ||
                profile.role === "ROLE_OPERATION") && (
                <Fragment>
                  {profile.role === "ROLE_OPERATION" && (
                    <li className="nav-item">
                      <Link
                        to="/dashboard/overview"
                        className={`top-menu ${
                          pathname === "/dashboard/overview" ? "active" : ""
                        } `}
                      >
                        Overview
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link
                      to="/dashboard/survey"
                      className={`top-menu ${
                        pathname === "/dashboard/survey" ? "active" : ""
                      } `}
                    >
                      Survey
                    </Link>
                  </li>
                </Fragment>
              )} */}
          </ul>

          <ul className="navbar-nav nav-right">
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-pill user-avatar"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="icon dripicons-user" />
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-account">
                <div className="dropdown-header pb-3">
                  <div className="media d-user">
                    <img src={logo} alt="logo" />
                    <div className="media-body">
                      <h5 className="mt-0 mb-0">
                        {profile ? profile.name : ""}
                      </h5>
                      <span>{profile ? profile.email : ""}</span>
                    </div>
                  </div>
                </div>
                <a
                  href="#"
                  className="dropdown-item"
                  onClick={() => this.props.setModalRegister()}
                >
                  <i className="icon dripicons-plus" /> Add User
                </a>
                <a
                  href="#"
                  className="dropdown-item"
                  onClick={() => this.props.signOut()}
                >
                  <i className="icon dripicons-lock" /> Sign Out
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <ModalRegister />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.auth.profile
});

const mapDispatchToProps = {
  signOut,
  setModalRegister
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
